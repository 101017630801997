import { TZDate } from "@date-fns/tz";
import { format } from "date-fns";
import { enUS, ja } from "date-fns/locale";

/**
 * 日付を指定された書式でフォーマットした文字列を返します。
 * @param {string|Date} date Date型オブジェクトもしくはISO 8601形式（Tは半角スペースでも可）の日付文字列
 * @param {string} formatPattern date-fnsのフォーマット用書式文字列
 * @param {import("svelte-i18n").locale} locale svelte-i18nのlocale
 * @param {object} formatOptions date-fnsのフォーマットオプション（localeは自動設定）
 * @returns {string} フォーマット済の日付文字列
 */
export function formatDate(date, formatPattern, locale, formatOptions = {}) {
  let parsedDate;
  if (date instanceof Date) {
    parsedDate = date;
  } else if (typeof date === "string") {
    parsedDate = new TZDate(date, "Asia/Tokyo");
  } else {
    throw new TypeError(typeof date);
  }

  if (locale === "ja") {
    formatOptions.locale = ja;
  } else {
    formatOptions.locale = enUS;
  }
  return format(parsedDate, formatPattern, formatOptions);
}

/**
 * 日本標準時の時刻に変換します。
 * パラメータが無い場合は現在時刻を返します。
 * @param {string|Date} [date]
 * @returns {Date} 日本標準時の現在時刻
 */
export function getJstDate(date) {
  let parsedDate;
  if (date instanceof Date) {
    parsedDate = date;
  } else if (typeof date === "string") {
    parsedDate = new Date(date);
  }

  if (parsedDate) {
    return new TZDate(parsedDate, "Asia/Tokyo");
  } else {
    return new TZDate(new Date(), "Asia/Tokyo");
  }
}
